@use './mixins.scss' as mixins;
@use '~bootstrap/scss/bootstrap';
@use './fonts.scss';

body,
html {
    margin: 0;
    padding: 0;
}

html,
body,
#root {
    height: 100%;
}

h1 {
    @include mixins.theme('background', 'ui-primary-color');
    @include mixins.theme('border-radius', 'primary-header-border-radius');
    @include mixins.theme('padding', 'primary-header-padding');
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    font-size: 1rem;
    margin: 15px 0;
    border-bottom-right-radius: 0 !important;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;

    img {
        @include mixins.theme('width', 'primary-header-image-size');
    }

    label {
        flex: 2;
        text-align: center;
        margin: 0;
    }
}

h2 {
    @include mixins.theme('background', 'ui-primary-color');
    @include mixins.theme('padding', 'secondary-header-padding');
    @include mixins.theme('border-radius', 'secondary-header-border-radius');
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    box-sizing: border-box;
    color: #fff;
    font-weight: bold;
    font-size: 0.85rem;
    text-align: center;
    width: 50%;
    margin: 15px 0 2px 0;
}

h5 {
    font-weight: bold;
    color: #204381;
}

.jumbotron {
    background-color: #e9ecef;
}

#cbc-job-form>div ::placeholder {
    font-style: italic;
    opacity: 0.4 !important;
}

.primary-colour {
    @include mixins.theme('color', 'ui-primary-color');
}

.container {
    flex: 1;
}

.updates {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 0.5rem;
    max-height: 60px;
}

.contact-info {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1rem;
    max-height: 60px;
}

.loading-screen {
    position: fixed;
    opacity: 0.5;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    padding-top: 300px;
    box-sizing: border-box;
    z-index: 5;
    background: rgba(255, 255, 255, 0.35);
}

.loading-absolute {
    position: absolute !important;
    padding-top: 40% !important;

    &.pt-small {
        padding-top: 15% !important;
    }
}

header {
    box-shadow: 0 0 7px 0px #999999;
    position: relative;
    z-index: 4;
}

.button-blue {
    @include mixins.buttons;
    @include mixins.theme('background', 'ui-secondary-color');
    @include mixins.theme('color', 'button-text-color');
    text-decoration: none;
}

.button-blue:disabled {
    @include mixins.theme('color', 'button-text-color');

    span {
        @include mixins.theme('color', 'button-text-color');
    }
}

.button-blue:hover,
.button-blue:active,
.button-blue:focus {
    @include mixins.theme('background-color', 'ui-primary-color');
    @include mixins.theme('color', 'button-text-color');
    text-decoration: none;
}

.button-transparent {
    @include mixins.buttons;
    @include mixins.theme('color', 'button-text-color');
    background: none;
    border: 1px solid #ffffff !important;
}

.button-transparent:hover,
.button-transparent:active,
.button-transparent:focus {
    background-color: none;
    text-decoration: none;
    @include mixins.theme('color', 'button-text-color');
}

.button-light {
    @include mixins.buttons;
    @include mixins.theme('color', 'button-text-color');
    background: #fff;
    border: 1px solid #6b6f70 !important;
}

.button-light:hover,
.button-light:active,
.button-light:focus {
    background-color: #fff;
    text-decoration: none;
    @include mixins.theme('color', 'button-text-color');
}

.button-light {
    span {
        color: #6b6f70;
    }
}

.scale6 {
    -moz-transform: scale(0.6);
}

.button-blue.btn-sm,
.button-light.btn-sm {
    font-size: 1.15em !important;
    padding: 0.2em 0.5em 0.2em 0.2em !important;
    line-height: 19px;
    @include mixins.theme('color', 'button-text-color');

    img {
        width: 25px;
    }
}

.button-danger {
    @include mixins.buttons;
    background: #dc3545;
    color: white;
}

.button-danger:hover,
.button-danger:active,
.button-danger:focus {
    background-color: #dc3545;
    text-decoration: none;
    color: white;
}

.sidebar-floating-button {
    @include mixins.theme('background', 'ui-header-background');
    @include mixins.theme('width', 'sidebar-switch-width');
    @include mixins.theme('height', 'sidebar-menu-height');
    text-align: center;
    box-sizing: border-box;
    padding: 15px;
    border-radius: 0;
    margin-top: -15px;
    margin-bottom: -15px;
    display: block;

    img {
        width: 40px !important;
    }
}

.sidebar-dashboard-button {
    box-shadow: 0 -2px 7px 0px grey;
}

.sidebar-cart-toggle-button,
.sidebar-cart-toggle-button:hover,
.sidebar-cart-toggle-button:active,
.sidebar-cart-toggle-button:focus {
    box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.35) !important;
}

.notes {
    margin-top: 25px;
    border: solid 2px;
    border-color: #204381;
    border-radius: 25px;
    padding-left: 13px;
    padding-top: 5px;
    font-size: 13px;
}

#cbc-input-group {
    border-right: none;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.cbc-input-group-icon {
    width: 25px;
}

.classic-view-button {
    font-size: 0.8rem;
    font-weight: 500;
}

.beta-banner {
    position: relative;

    span {
        @include mixins.theme('background-color', 'ui-primary-color');
        @include mixins.theme('color', 'ui-header-background');
        font-size: 1.4em;
        font-weight: 900;
        display: block;
        width: 126px;
        text-align: center;
        transform: rotate(48deg);
        letter-spacing: 0.08em;
        line-height: 27px;
    }
}

/*CSS for custom scrollbar*/
/* width */
::-webkit-scrollbar {
    @include mixins.theme('width', 'ui-scrollbar-width');
}

/* Track */
::-webkit-scrollbar-track {
    @include mixins.theme('background', 'ui-scrollbar-background');
    @include mixins.theme('-webkit-box-shadow', 'ui-scrollbar-track-box-shadow');
}

/* Handle */
::-webkit-scrollbar-thumb {
    @include mixins.theme('background', 'ui-scrollbar-color');
    @include mixins.theme('border-radius', 'ui-scrollbar-curve');
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    @include mixins.theme('background', 'ui-scrollbar-hover-color');
}

/*CSS for custom scrollbar*/

.more-info {
    top: 0;
    right: 0;

    button {
        padding: 0;
        margin: 0;

        img {
            width: 20px;
        }
    }
}

.dynamic-radio-more-info {
    div.more-info {
        @extend .more-info;
        top: 0;
        right: 0;
    }
}

.dynamic-radio-checkbox {
    label {
        padding-right: 25px;
        width: 100%;
        text-align: center;
        font-size: 15px;
    }

    &.small label {
        padding-right: 0;
    }
}

.info-modal {
    .btn-primary {
        @include mixins.theme('background', 'ui-primary-color');
    }
}

.modal-title {
    font-size: 1em;
}

.modal-body {
    font-size: 0.85em;

    img {
        width: 100%;
    }

    .wyswyg_editor_class {
        min-height: 200px;
    }
}

// div:not(.imageContainer):not(.cbc-wizard.nav):not(.cbc-wizard-navigation):not(.spinner-border):not(.alert):not(.modal-header):not(.modal-content):not(.modal-body):not(.modal-footer):not(.list-group-item):not(.tooltip-inner):not(.sidebar-left-toggle):not(.info-modal .wyswyg_editor_class div){
//     @include theme('border-color', 'ui-primary-color', 1);
// }

.modal-footer {
    button {
        border-radius: 10px;
        padding: 5px 10px;
        border: 0;
        font-size: 0.85em;
        font-weight: 500;
    }
}

.form-check input[type="radio"] {
    outline: none;
    display: none;
}

.cbc-radio {
    .form-check {
        position: relative;
        margin-bottom: 3px;

        label {
            margin-left: 10px;
            user-select: none;
        }

        [type='radio']:checked+label::before {
            @include mixins.theme('background', 'ui-secondary-color');
            height: 10px;
            width: 10px;
            top: 8px;
            left: 5px;
            content: '';
            position: absolute;
            z-index: 1;
            display: block;
        }

        [type='checkbox']:checked+label::before {
            content: '\2713';
            top: -1px;
            left: 0;
            position: absolute;
            z-index: 1;
            display: block;
            right: 0;
            bottom: 0;
            color: white;
            font-weight: bold;
            font-size: 17px;
            box-sizing: border-box;
            padding-left: 3px;
        }

        [type='checkbox']:checked+label::after {
            @include mixins.theme('background', 'ui-secondary-color');
        }

        label::after {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            background: white;
            @include mixins.theme('border-color', 'ui-primary-color');
            border: 1px solid;
            left: 0;
            top: 3px;
            box-shadow: inset 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
        }
    }
}

.await-radio {
    .form-check {

        label::before,
        [type='radio']:checked+label::before {
            display: none;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        label::after {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            border: 3px solid transparent;
            border-color: #3498db #3498db #3498db transparent !important;
            border-radius: 50%;
            left: 0;
            top: 3px;
            box-shadow: none;
            animation: spin 2s linear infinite;
        }
    }
}

.product-detail {
    display: flex;

    >div:last-child {
        padding-left: 15px;
        align-self: center;
    }

    h3 {
        text-align: left;
        font-weight: 600;
        font-size: 1.2em;
        @include mixins.theme('color', 'ui-secondary-color');
    }

    p {
        text-align: justify;
        margin: 0;
        word-break: break-word;
    }

    strong {
        @include mixins.theme('color', 'ui-primary-color');
    }

    .image-container {
        @include mixins.theme('border-color', 'ui-primary-color');
        border: 1px solid;
        border-radius: 10px;
        width: 150px;
        height: 150px;
        background: white;
        display: flex;

        img {
            width: 100%;
            object-fit: contain;
            flex: 1;
            box-shadow: inset 0 0 2px 2px white;
            z-index: 2;
            position: relative;
        }
    }
}

.cbc-radio.round {
    .form-check {
        label::after {
            border-radius: 20px;
            border-color: #1793cd;
            top: 0;
            box-shadow: none;
        }

        [type='radio']:checked+label::before {
            border-radius: 10px;
            height: 12px;
            width: 12px;
            top: 4px;
            left: 4px;
        }
    }
}

.cbc-form {
    .form-group label {
        @include mixins.theme('color', 'ui-primary-color');
        font-weight: 500;
        font-size: 0.85em;

        span {
            @include mixins.theme('color', 'ui-danger-color');
        }
    }

    .form-control {
        @include mixins.theme('border-color', 'ui-primary-color');
        border-width: 2px;
        border-radius: 8px;
        min-width: 50px;
    }

    .form-control.is-invalid {
        background: #f4cfc0;
    }

    .invalid-feedback {
        background-color: #c92434;
        color: #fff;
        padding: 5px 10px;
        box-sizing: border-box;
        border-radius: 14px;
        background-repeat: no-repeat;
        font-weight: 500;
        background-position: 5px 5px;
        background-size: 20px;
    }

    .thin {
        font-weight: 500 !important;
    }

    .input-group {
        input {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        .input-group-append {
            border: 0;
            background: #fff;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;

            .input-group-text {
                background: #fff;
                border: 0;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                padding: 0 8px 2px;
                font-size: 0.85em;
                font-weight: 500;
                color: #6b6f70;
            }
        }
    }

    .cbc-size.input-group {
        input {
            border-top-right-radius: 8px !important;
            border-bottom-right-radius: 8px !important;
        }
    }
}

.tooltip.bs-tooltip-right .arrow:before {
    border-right-color: #c5c9ca;
}

.tooltip.bs-tooltip-top .arrow:before {
    border-top-color: #c5c9ca;
}

.tooltip.show {
    opacity: 1 !important;
}

.tooltip.show.error-popover {
    .arrow::before {
        border-right-color: rgba(201, 36, 52, 0.95) !important;
    }

    .arrow::after {
        border-left-color: rgba(201, 36, 52, 0.95) !important;
    }

    .tooltip-inner {
        max-width: 300px;
        background: rgba(201, 36, 52, 0.95);
        text-align: left;
        color: white;
        border: 0;
        border-radius: 8px;
        font-weight: 600;
        font-size: 0.75rem;
        padding: 15px;
        border: 2px solid white;

        ul {
            padding-left: 10px;
            margin-bottom: 0;
        }
    }
}

.tooltip.show.error-popover.bs-tooltip-bottom {
    .arrow::before {
        border-bottom-color: rgba(201, 36, 52, 0.95) !important;
        border-right-color: rgba(0, 0, 0, 0) !important;

    }
}

.tooltip,
.tooltip.show {
    .tooltip-inner {
        color: #868686;
        text-align: center;
        background-color: #dadedf;
        border: 1px solid #c5c9ca;
        border-radius: 0;
        margin: 0;
    }
}

.alert {
    .close {
        padding: 0 0.5rem !important;
    }
}

.mainContainer {
    height: 100%;
    display: flex;
    align-items: stretch;
    overflow-y: hidden;

    div.mobile-menu {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        @include mixins.theme('background', 'mobile-menu-background');
        z-index: 9999;
        padding: 15px;
        box-sizing: border-box;
        box-shadow: 0 3px 9px 3px grey;

        .job-menu {
            width: 100%;

            .nav-link {
                width: 20%;
                @include mixins.theme('color', 'ui-primary-color');
                font-weight: bold;
                text-align: center;
                padding: 0;

                img {
                    width: 40px;
                    margin-bottom: 5px;
                }

                label.toggle {
                    @include mixins.theme('color', 'ui-secondary-color');
                }

                label {
                    font-size: 0.65em;
                    margin-bottom: 0;
                }
            }
        }
    }

    .sidebar {
        @include mixins.theme('background', 'ui-sidebar-background');
    }

    .sidebar-left {
        box-shadow: 0 0px 7px 0px grey;
        z-index: 5;
        display: flex;
        flex-direction: column;
        transition: left 300ms, position 300ms;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;

        .sidebar-left-toggle:hover,
        .sidebar-left-toggle:focus,
        .sidebar-left-toggle:active,
        .sidebar-left-toggle {
            @include mixins.theme('color', 'ui-primary-color');

            & {
                font-size: 12px;
                box-shadow: 0 0 1px 0px #999999;
                text-decoration: none;
                padding: 15px 0;
            }
        }

        .sidebar-left-content {
            flex: 1;
            position: relative;
            display: flex;
            flex-direction: column;
            height: 100%;

            .nav-container {
                opacity: 1;
                padding: 2vh 0;
                height: 96vh;
                overflow-y: auto;
                overflow-x: hidden;

                .nav {
                    a {
                        @include mixins.sidebar-left-menu;

                        label {
                            min-width: 155px;
                        }
                    }

                    a.disabled {
                        opacity: 0.6;
                    }
                }

            }
        }

        .submit-button-left {

            @include mixins.sidebar-left-menu;
            text-align: center;

            img {
                vertical-align: middle;
                border-style: none;
                margin-left: 20px;
            }

            span {
                color: rgba(var(--navigation_text_colour), 1);
                width: 107px;
                display: inline-block;
            }


        }

        .sidebar-footer-left {
            box-sizing: border-box;

            button {
                box-sizing: border-box;
                width: 100%;
                border-radius: 0 !important;
            }

            :hover {
                background-color: #B0C9E7;
                text-decoration: none !important;
            }

        }

        .dashboard-link {
            @include mixins.theme('background', 'ui-header-background');
            @include mixins.sidebar-left-menu;
            box-shadow: 0 0 7px 0px #999999;
            flex-shrink: 0;

            &>label {
                @include mixins.theme('color', 'sidebar-header-nav-color');
            }
        }

        .job-menu a:hover,
        .job-menu a:focus,
        .job-menu a:active,
        .job-menu a.active {
            text-decoration: none;
            @include mixins.theme('background', 'ui-highlight-color');
        }
    }

    .sidebar-left.toggle,
    .sidebar-left.float.fixed {
        position: initial;
    }

    .sidebar-left.toggle.fixed {
        width: 240px;
    }

    .sidebar-left.float.closed {
        left: -85px;
    }

    .sidebar-left.float.open {
        left: 0;
        width: 100px;
    }

    .sidebar-left.hidden {
        display: none;
    }

    .sidebar-right {
        @include mixins.theme('width', 'sidebar-right-width');
        box-shadow: 3px 3px 9px 2px gray;
        box-sizing: border-box;
        z-index: 6;
        display: flex;
        flex-direction: column;
        height: 100%;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        transition: right 300ms, position 300ms;

        .sidebar-header {
            @include mixins.theme('height', 'sidebar-menu-height');
            @include mixins.theme('background', 'ui-header-background');
            display: flex;
            align-items: center;
            font-size: 12px;
            box-sizing: border-box;
            padding: 15px;
            box-shadow: 0 0 7px 0px #999999;

            label {
                font-weight: bold;
                margin: 0 0 0 10px;
                @include mixins.theme('color', 'sidebar-header-nav-color');
            }

            div.center-button {
                flex: 3;
                text-align: center;

                .btn {
                    font-size: 12px;
                    display: inline-block !important;
                }
            }

            div.text-right {
                .btn {
                    font-size: 12px;
                    padding: 0;
                    font-weight: bold;
                    @include mixins.theme('color', 'sidebar-header-nav-color');
                }
            }

            &>img {
                @include mixins.theme('width', 'sidebar-icon-size');
            }

            .pointer {
                cursor: pointer;
            }
        }

        .toggle {
            display: flex;
            width: 100px;
            align-items: center;
            justify-content: flex-end;

            img {
                width: 40px;
            }

            label {
                text-align: center;
                user-select: none;
                cursor: pointer;
            }
        }

        .sidebar-content {
            flex: 1;
            padding: 15px 15px 0;
            box-sizing: border-box;
            overflow-x: visible !important;
            position: relative;
            overflow-y: auto;

            h2:first-of-type {
                margin-top: 0;
            }

            h2 {
                margin: 10px 0 5px;
                width: 100%;
                text-align: left;
                padding-left: 10px;
                box-sizing: border-box;
                font-size: 0.65em;
                font-weight: 500;
            }

            .detail-export {
                display: flex;

                div:first-child {
                    flex: 1;
                    max-width: 73%;

                    .jobId {
                        font-size: 0.8em;
                        font-weight: 700;
                        @include mixins.theme('color', 'ui-primary-color');
                    }

                    .jobTitle {
                        font-size: 0.7em;
                        font-weight: 500;
                        color: #6b6f70;
                        word-break: break-all;
                    }
                }
            }

            .job-detail-button {
                margin-top: 15px;
                width: 100%;
            }

            table {
                margin-top: 15px;
                margin-bottom: 0;

                thead tr th {
                    color: white;
                    padding: 3px;
                    font-size: 1em;
                    @include mixins.theme('background', 'ui-primary-color');
                    border: 0;
                    text-align: center;
                    font-weight: 500;
                }

                thead tr th:first-of-type {
                    border-top-left-radius: 8px;
                    text-align: left;
                    box-sizing: border-box;
                    padding-left: 10px;
                }

                thead tr th:last-of-type {
                    border-top-right-radius: 8px;
                }

                tbody tr:first-of-type td {
                    padding-top: 5px;
                }

                tbody tr {
                    background: transparent;
                }

                tbody tr td {
                    padding: 3px 0;
                    color: #57585a;
                    font-size: 1em;
                    background: transparent;
                    text-align: center;
                    height: auto;
                    font-weight: 400;

                    .cbc-radio .form-check label::before {
                        border-radius: 10px;
                        height: 12px;
                        width: 12px;
                        top: 4px;
                        left: 4px;
                    }

                    .cbc-radio .form-check label::after {
                        border-radius: 20px;
                        @include mixins.theme('border-color', 'ui-secondary-color');
                        top: 0;
                        box-shadow: none;
                    }
                }

                tbody tr td:last-child {
                    text-align: right;
                }

                tbody tr td:first-of-type {
                    text-align: left;
                }
            }
        }

        .sidebar-footer {
            box-sizing: border-box;

            .totals {
                margin: 15px 0 0;
                padding: 0;
                list-style: none;
                font-weight: 500;
                font-size: 0.85em;
                @include mixins.theme('color', 'ui-secondary-color');

                li:last-child {
                    margin-bottom: 0;
                }

                li {
                    display: flex;
                    background: #fcfcfc;
                    margin-bottom: 1px;
                    padding: 2px 15px;

                    span:first-child {
                        flex: 1;
                    }
                }

                .cost {
                    font-size: 1.1em;
                    font-weight: 700;
                }
            }

            button {
                box-sizing: border-box;
                width: calc(100% - 30px);
                margin: 15px;
            }
        }
    }

    .sidebar-right.toggle:not(.soft-open),
    .sidebar-right.float.fixed:not(.soft-open) {
        position: initial;
    }

    .sidebar-right.hidden {
        display: none;
    }

    .sidebar-right.toggle.closed {
        display: none;
    }

    .sidebar-right.float.closed {
        right: -385px;
    }

    .mainContent.modal-open {
        padding-right: 0 !important;
    }

    .mainContent {
        flex: 1;
        position: relative;
        z-index: 4;

        .absolute-backdrop {
            position: absolute;
            opacity: 0.3;
            background-color: #9e9e9e;
        }

        .confirmationDialog {
            position: absolute;
        }

        .component-section {
            height: calc(100% - 70px);
            overflow: auto;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
        }
    }
}

.job-page.mainContainer {
    .component-section {
        >.container {
            max-width: 100%;
        }
    }

    .main-header {
        >.container {
            max-width: 100%;
        }
    }
}

.header-grid {
    display: flex;
    align-items: center;

    .logo {
        a {
            width: 150px;
            display: inline-block;
            @include mixins.theme('color', 'sidebar-header-nav-color');
        }
    }

    .notification-bell-container {
        flex: 8;
    }
}

@mixin alert {
    font-weight: bold;
    font-size: 0.85em;
    margin-bottom: 0;
    margin-top: 15px;
    border-radius: 30px;
    padding: 3px 40px 3px 3px;
    display: flex;
    align-items: center;

    img {
        width: 25px;
        margin-right: 15px;
    }
}

.alert-danger {
    background: #c92434;
    color: white;

    @include alert;
}

.alert-success {
    @include alert;
}

.alert-warning {
    @include alert;
    font-size: 0.9rem;
    margin: 0;
    padding: 0.4rem 1.2rem;
    border-radius: 8px;
    border-color: #bf9000;
}

// .btn:focus,.btn:active,.btn:hover {
//     outline: none !important;
//     box-shadow: none !important;
// }

.header-contents {
    position: relative;
    @include mixins.theme('background', 'ui-header-background');
    height: mixins.$header-height;
    align-items: center;

    display: flex;
    align-items: center;

    .main-header {
        flex: 1;
    }

    .logo {
        img {
            width: 100%;
            height: 70px;
            object-fit: scale-down;
        }
    }

    .notification-bell-container {
        text-align: right;

        .notification-bell {
            position: relative;
            padding-top: 5px;

            img {
                width: 25px;
            }

            .badge {
                position: absolute;
                right: 5px;
                top: 0;
                border-radius: 10px;
                height: 20px;
                text-align: center;
                border: 1px solid #fff;
                width: 20px;
                padding: 2px 0;
            }
        }
    }

    .dropdown-toggle-front {
        border-radius: 8px 0 0 8px;
        border: 2px solid #fff;
        border-right: 0;
        font-size: 0.85em;
        @include mixins.theme('background', 'ui-primary-icon-color');
        padding: 0;

        img {
            width: 35px;
            border-radius: 8px 0 0 8px;
            border-right: 2px solid #fff;
        }
    }

    .dropdown-toggle:hover,
    .dropdown-toggle:active,
    .dropdown-toggle:focus {
        @include mixins.theme('background', 'ui-secondary-color');
        border-color: #fff;
        box-shadow: none;
    }

    .dropdown-toggle {
        @include mixins.theme('width', 'ui-main-menu-width');
        @include mixins.theme('background', 'ui-secondary-color');
        text-align: left;
        border: 2px solid #fff;
        border-radius: 0 8px 0 0;
        font-size: 0.85em;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .dropdown-toggle:after {
        position: absolute;
        right: 10px;
        top: 15px;
    }

    .dropdown {
        &>.dropdown-menu {
            @include mixins.theme('width', 'ui-main-menu-width');
        }
    }

    .dropdown-menu {
        min-width: 0;
        width: 163px;
        border: 0;
        border-radius: 0 0 8px 8px;
        padding: 0;

        .dropdown-item:nth-child(even) {
            @include mixins.theme('background', 'ui-stripe-second-color');
        }

        .dropdown-item:last-child {
            border-radius: 0 0 8px 8px;
        }

        .dropdown-item {
            white-space: normal;
            @include mixins.theme('background', 'ui-stripe-first-color');
            color: #fff;
            padding: 10px;
            font-size: 0.85em;

            img {
                width: 20px;
                margin: 0 5px 0 0;
            }
        }

        .dropdown-item.submenu:hover {
            &>.dropleft>.dropdown-menu {
                display: block !important;
            }
        }

        .dropdown-item.submenu {
            padding: 0;

            .dropleft {
                position: relative;

                .dropdown-toggle {
                    width: 100%;
                    background: transparent;
                    border: 0;
                    padding: 10px;
                    margin: 0;
                    font-size: inherit;
                }

                .dropdown-menu {
                    position: absolute;
                    display: none;
                    border-radius: 0 8px 0 8px;
                    transform: none !important;
                    top: 0 !important;
                    left: -163px !important;
                    box-shadow: 0px 4px 9px -2px #000;

                    .dropdown-item:first-child {
                        border-radius: 0 8px 0 0;
                    }

                    .dropdown-item:last-child {
                        border-radius: 0 0 0 8px;
                    }
                }
            }
        }
    }
}

.small-screen {
    .header-contents {
        height: mixins.$header-height-small;

        .sidebar-floating-button {
            height: inherit;
            padding: mixins.$header-button-padding-small;
        }

        .logo img {
            height: mixins.$header-logo-height-small;
        }
    }

    .mainContainer {
        .sidebar-left {
            .dashboard-link {
                height: mixins.$header-height-small;
            }

            .sidebar-left-content .nav-container .nav a {
                height: mixins.$header-height-small;
            }

            .dashboard-link img,
            .sidebar-left-content .nav-container .nav a img {
                width: mixins.$header-image-width-small;
            }

            .dashboard-link label,
            .sidebar-left-content .nav-container .nav a label {
                margin: 0;
                font-size: 0.5rem;
            }
        }

        .sidebar-left.fixed .sidebar-left-content .dashboard-link,
        .sidebar-left.fixed .sidebar-left-content .nav-container .nav a {
            >div {
                width: 65px;
            }
        }

        .sidebar-right {
            .sidebar-header {
                height: mixins.$header-height-small;

                img {
                    width: mixins.$header-image-width-small;
                }
            }
        }
    }

    .alert-warning {
        font-size: 0.5rem;
    }
}

.cbc-toast-container {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 5;

    .cbc-toast {
        border-radius: 5px;
        border: 1px solid;
        border-left: 5px solid;
        padding: 10px 25px 10px 10px;
        z-index: 5;
        width: 300px;
        font-size: 0.8em;
    }

    .cbc-toast.alert-danger {
        background-color: rgba(201, 36, 52, 0.5) !important;
        border-color: rgb(201, 36, 52) !important;
        color: rgb(201, 36, 52) !important;
    }
}

.cbc-toast {
    ul {
        padding-inline-start: 0px;
        list-style-type: none;
        margin-bottom: 0px;
    }
}

.cbc-toast-traisition-enter {
    opacity: 0;
    transform: scale(0.9);
}

.cbc-toast-traisition-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 300ms;
}

.cbc-toast-traisition-exit {
    opacity: 1;
}

.cbc-toast-traisition-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

.cbc-message-container {
    .alert {
        margin-top: 25px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        border-radius: 20px;
        padding: 4px;

        .close {
            top: 5px;
            right: 3px;
        }

        img {
            width: 30px;
            margin-right: 15px;
        }
    }

    .alert-warning {
        background: #ffc966;
    }
}

strong.productNumbers {
    white-space: nowrap;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.four04-page-button {
    width: 13em;
    display: inline-block !important;
}

.thin-text {
    font-weight: 500 !important;
}

.pdfDropDownCart {
    .dropdown-menu {
        background: #212529;

        .dropdown-item {
            padding: 0px 12px;
            color: #fff;
            font-size: 0.85em;
            background-color: transparent;
        }

        .dropdown-item:nth-of-type(odd) {
            background-color: var(--menu_primary_colour);
        }

        .dropdown-item:hover,
        .dropdown-item:focus {
            background-color: var(--menu_primary_colour);
        }
    }
}

.mainContent .top_color_strip {
    background-image: linear-gradient(45deg,
            black 25%,
            yellow 25%,
            yellow 50%,
            black 50%,
            black 75%,
            yellow 75%,
            yellow 100%);
    background-size: 25.46px 25.46px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    width: 100%;
    height: 10px;
}

.cbc-top-floating-error {
    position: fixed;
    border-radius: 17px;
    z-index: 2001;
    padding: 15px;
    font-size: 13px;
    right: 50px;
    bottom: 50px;

    div {
        margin-bottom: 5px;
    }

    div:last-of-type {
        margin: 0;
    }

    img {
        opacity: 0.8;
    }
}

.Products,
.room {
    .colorName {
        padding-top: 50px;
        display: block;
        margin-bottom: 15px;
        font-weight: 500;
        font-size: 0.9em;
        @include mixins.theme('color', 'ui-primary-color');
    }

    .colorSwatches {
        span {
            @include mixins.theme('color', 'ui-primary-color');
            font-weight: 500;
            font-size: 0.85em;
            margin-bottom: 10px;
            display: block;
        }

        img {
            width: 23px;
            height: 23px;
            border: 1px solid #6b6f70;
            box-sizing: border-box;
            margin: 0 1px 1px 0;
        }
    }
}

.dropdown-toggle.btn-danger {
    background: #f4cfc0 !important;
}

.highlight {
    font-weight: bold;
    color: #585858;
}

.nav-and-control {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 0 6px -2px gray;
    background: #fff;
    z-index: 4;

    .control-buttons {
        padding: 30px 0;
    }

    .cbc-wizard-navigation {
        padding: 15px 30px;
        border-bottom: 3px solid;
        @include mixins.theme('border-color', 'ui-secondary-color');
    }
}

.cbc-popover.small {
    .popover-body {
        font-size: 0.6rem !important;
    }
}

.theme-gocabinets .simplified-material-button.button-blue {
    width: 140px;
    padding: 3px;
    font-size: 0.75rem;
    font-weight: 500;
    margin: 0 0 0 auto;

    img {
        width: 20px;
    }
}

@media (min-width: 801px) {
    #search_results table {
        margin-left: 0px !important;
    }

    #search_results div.dropdown-menu {
        position: fixed !important;
    }

    header {
        .info {
            border: 8px solid;
            border-image: repeating-linear-gradient(45deg,
                    #000000,
                    #000000 10px,
                    #ffff00 10px,
                    #ffff00 20px) 10;
            margin: 7px 0 0 0px;
            font-weight: bolder;
            font-size: 1.5em;
            display: flex;
            background-color: white;
        }
    }
}

@media (min-width: 695px) {
    .nav-and-control {
        .cbc-wizard-navigation {
            padding: 15px 0;
        }
    }

    #search_results table {
        margin-left: 0px !important;
    }

    .modal-dialog {
        max-width: 500px;
    }

    .mobile .content-container.container {
        max-width: 100%;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1300px;
    }

    #search_results div.dropdown-menu {
        position: fixed !important;
    }

    .header-contents {
        .notification-bell-container {
            padding-right: 0;
        }
    }

    #search_results table {
        margin-left: 0px !important;
    }
}

@media (max-width: 800px) and (min-width: 696px) {
    .header-grid .info {
        font-size: 0.7rem;
        border: 3px solid;
        padding: 0 2px;
        font-weight: bold;
        display: flex;
        border-image: repeating-linear-gradient(45deg,
                #000000,
                #000000 10px,
                #ffff00 10px,
                #ffff00 20px) 10;
    }

    #search_results div.dropdown-menu {
        position: fixed !important;
    }

    #search_results table {
        margin-left: 0px !important;
    }

    .search:focus,
    .search:not(:placeholder-shown),
    .search-box {
        width: 481px !important;
    }

    #search_results {
        width: 530px !important;
    }
}

@media (max-width: 695px) {
    .searchContainer {
        order: 2;
    }

    .search:focus,
    .search:not(:placeholder-shown),
    .search-box {
        width: 456px !important;
    }

    #search_results table {
        margin-left: 10px !important;
    }

    #search_results {
        width: 505px !important;
    }

    .header-grid .info {
        font-size: 0.6rem;
        border: 3px solid;
        padding: 0 2px;
        font-weight: bold;
        display: flex;
        border-image: repeating-linear-gradient(45deg,
                #000000,
                #000000 10px,
                #ffff00 10px,
                #ffff00 20px) 10;
    }

    .mainContent .top_color_strip {
        height: 10px;
    }

    .mainContainer .mainContent {
        width: 100%;
    }

    .content-container {
        padding-left: 10px;
        padding-right: 10px;
        overflow-x: hidden;
    }

    .mainContainer div.mobile-menu .job-menu {
        a.disabled {
            opacity: 0.6;
        }
    }

    #search_results div.dropdown-menu {
        position: fixed !important;
    }

    .header-contents {
        .float-right {
            float: inherit !important;
        }

        .main-menu .dropdown-menu {
            top: 55px !important;
            transform: none !important;
        }

        .main-menu {
            order: 1;
        }

        .logo {
            order: 2;
            flex: 6;
            text-align: center;
        }

        .notification-bell-container {
            order: 3;
            flex: 1 !important;
        }

        .dropdown-toggle {
            width: auto !important;
            border-radius: 0 8px 8px 0 !important;

            span {
                display: none;
            }
        }

        .dropdown-menu {
            border-top: 0 !important;

            .dropdown-item.submenu {
                padding: 0;

                .dropright {
                    position: relative;

                    .dropdown-toggle {
                        width: 100% !important;
                        background: transparent;
                        border: 0;
                        padding: 10px;
                        margin: 0;
                        font-size: inherit;
                    }

                    .dropdown-menu {
                        position: absolute;
                        border-radius: 0 8px 0 8px;
                        transform: none !important;
                        top: 0 !important;
                        box-shadow: 0px 4px 9px -2px #000;

                        .dropdown-item:first-child {
                            border-radius: 0 8px 0 0;
                        }

                        .dropdown-item:last-child {
                            border-radius: 0 0 0 8px;
                        }
                    }

                    &>.dropdown-menu {
                        left: 166px !important;

                        .dropdown-menu {
                            left: -166px !important;

                            .dropdown-menu {
                                left: 166px !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .mobile-cart {
        width: auto !important;

        .sidebar-right {
            left: 0;
        }

        .sidebar-content {
            overflow-y: auto !important;
            padding: 10px 20px !important;
        }

        .sidebar-footer {
            padding: 0px 0px 100px 0px;
        }
    }

    .modal.mobile {
        img {
            width: 100%;
            height: auto;
        }
    }

    .cbc-toast-container.mobile {
        width: 100%;
        right: 0;
        left: 0;

        .cbc-toast {
            width: initial;
            margin: 0 15px 5px;
        }
    }

    .main-menu {
        width: 75px;
    }

    .logo {
        a {
            width: initial !important;
        }
    }

    .classic-view-button {
        order: 3;
        padding: 0;
        margin: 0;
        width: 75px;
        position: relative;
        height: 35px;

        span {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: -30px;
            cursor: pointer;
            display: block;
            z-index: 99;
        }
    }

    .beta-banner {
        position: absolute;
        position: absolute;
        top: 16px;
        right: -40px;
    }
}

@media screen and (max-width: 575px) {

    .search:focus,
    .search:not(:placeholder-shown),
    .search-box {
        width: calc(100vmin - 88px) !important;
    }

    #search_results {
        width: calc(100vmin - 40px) !important;
    }

    #search_results table {
        margin-left: 10px !important;
    }
}

.tooltip {
    z-index: 9999 !important;
}

.lock {
    background: gray;
    border-radius: 3px;
    width: 18px;
    height: 15px;
    position: relative;
    margin-top: 3px;
    margin-right: 10px;
}

.lock:before {
    content: '';
    display: block;
    position: absolute;
    border: 3px solid gray;
    top: -8px;
    left: 2.3px;
    width: 11px;
    height: 20px;
    border-radius: 35px 35px 0 0;
}

.s_button {
    display: inline-block;
    margin: 2px 5px;
    background-color: rgba(var(--secondary_colour), 1);
    font-size: 12px;
    padding-left: 0;
    padding-right: 0;
    height: 34px;
    line-height: 30px;
    text-align: center;
    color: rgba(var(--button_text_colour), 1);
    text-decoration: none;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 4px;
}

.s_button:hover {
    transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    background-color: rgba(var(--primary_colour), 1);
}

.searchOnText {
    border: 2px solid #fff !important;
    border-right: 0px !important;
}

.searchOnButton {
    border: 0px;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.search-container {
    position: relative;
    display: inline-block;
    margin: 1px 5px;
    height: 34px;
    width: 50px;
    z-index: 3;
    vertical-align: bottom;
}

.mglass {
    display: inline-block;
    pointer-events: none;
}

.mglass img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(275deg) brightness(103%) contrast(101%);
    height: 20px;
    width: auto;
}

.searchbutton {
    position: absolute;
    font-size: 22px;
    width: 100%;
    margin: 0;
    padding: 0;
}

.search:focus,
.search:not(:placeholder-shown),
.search-box {
    width: 600px;
}

.search:not(:placeholder-shown) {
    padding: 0 0 0 16px;
}

.search:focus+.searchbutton img,
.search:not(:placeholder-shown)+.searchbutton img {
    filter: none;
}

.search:focus+.searchbutton,
.search:not(:placeholder-shown)+.searchbutton {
    transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    background-color: white;
    color: black;
    pointer-events: none;
}

.search:not(:focus)+.searchbutton {
    pointer-events: all;
}

.search {
    position: absolute;
    left: 49px;
    /* Button width-1px (Not 50px/100% because that will sometimes show a 1px line between the search box and button) */
    background-color: white;
    outline: none;
    border: none;
    padding: 0;
    width: 0;
    height: 100%;
    z-index: 10;
    transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    font-size: 14px;
    color: #204381;
}

.opt_message {
    padding-left: 25px !important;
}

.expandright {
    left: auto;
    right: 49px;
    /* Button width-1px */
}

.expandright:focus {
    padding: 0 0 0 16px;
}

.searchResult_JobName {
    text-align: left !important;
}

#search_results tbody tr {
    cursor: pointer;
}

#search_results th:last-child {
    border-radius: 0px !important;
}

#search_results {
    height: auto;
    position: absolute;
    right: 0%;
    width: 649px;
    margin-top: 39px;
    background: #fff;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
}

.small_popup {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    padding: 25px;
    border-radius: 3px;
    margin: 0px 0px 14px 15px;
    border: 1px solid #a2dffb;
    font-size: 13px;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
}

#search_results thead {
    position: sticky;
    top: 0;
    z-index: 1;
}

#search_results {
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 3;
}

.searchText,
.small_popup span {
    font-size: 14px;
    color: #aaa;
    padding-left: 10px;
}

#search_message {
    padding: 10px;
}

#search_spinner {
    padding: 10px;
}

.overlayOff {
    width: 0%;
    opacity: 0;
}

.overlayOn {
    width: 100%;
    opacity: 0.7;
}

.search_overlay {
    height: 100%;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: #333;
    overflow-x: hidden;
    transition: 0.2s;
}

.search_option_icon {
    width: 25px;
}

.searchBarDropDown {
    width: 22px;
    padding: 0;
}

#search_results .dropdown-toggle {
    height: 20px;
    width: 20px;
    margin: 0;
    background: none;
}

.sideBarOverlay {
    position: absolute;
}

.relativePos {
    position: relative !important;
}

#search_results table tr {
    pointer-events: none;
}

#search_results button,
#search_results div.dropdown-menu {
    pointer-events: all;
}

.hidden_field {
    display: none;
}

.modal-dialog {
    &.modal-md {
        @media (min-width: 801px) {
            width: 800px;
            max-width: 800px;
        }

        @media (min-width: 1021px) {
            width: 1000px;
            max-width: 1000px;
        }
    }
}

.full-width {
    width: 100%;
}