@use './themes.scss' as themeStyles;
@use 'sass:map';

@mixin theme($key, $value, $important: 0) {
    @each $theme-name, $theme-value in themeStyles.$themes {
        .theme-#{$theme-name} & {
            @if $important == 0 {
                #{$key}: map.get(map.get(themeStyles.$themes, $theme-name), $value);
            } @else {
                #{$key}: map.get(map.get(themeStyles.$themes, $theme-name), $value) !important;
            }
        }
    }
}

@mixin jumbo-buttons {
    flex: 1;
    text-align: center;

    button{width: 100%;}
    
    strong{@include theme('color', 'ui-primary-color');}
}

@mixin more-link {
    color: #1793CD !important;
    display: block;
    text-align: center;
    font-weight: bold;
}

@mixin sidebar-left-menu {
    @include theme('height', 'sidebar-menu-height');
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 0;
    font-size: 12px;
    
    label {flex: 3; padding-right: 15px; @include theme('color', 'ui-nav-color');}
    div {
        @include theme('width', 'sidebar-switch-width');
        text-align: center;
    }

    img {
        @include theme('width', 'sidebar-icon-size');
    }
}

@mixin buttons {
    color: #fff; 
    text-transform: capitalize; 
    border-radius: 30px; 
    align-items: center;
    padding-left: .4em;
    display: flex;
    box-sizing: border-box;
    font-weight: bold;

    img {
        margin-right:.4em;
        @include theme('width', 'button-title-icon-size');
    }

    span {
        flex: 2;
        text-align: center;
    }
}

@mixin width($width) {
    min-width: $width;
    width: $width;
}

$header-height: 70px;
$header-height-small: 50px;
$header-logo-height-small: 40px;
$header-button-padding-small: 5px;
$header-image-width-small: 30px;