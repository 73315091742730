@font-face {
    font-family: OpenSans;
    src: url('assets/fonts/OpenSans-Light.ttf') format('truetype'),
        url('assets/fonts/OpenSans-Light.woff') format('woff'),
        url('assets/fonts/OpenSans-Light.woff2') format('woff2');
    font-weight: 100;
}

@font-face {
    font-family: OpenSans;
    src: url('assets/fonts/OpenSans-Regular.ttf') format('truetype'),
        url('assets/fonts/OpenSans-Regular.woff') format('woff'),
        url('assets/fonts/OpenSans-Regular.woff2') format('woff2');
    font-weight: 400;
}

@font-face {
    font-family: OpenSans;
    src: url('assets/fonts/OpenSans-SemiBold.ttf') format('truetype'),
        url('assets/fonts/OpenSans-SemiBold.woff') format('woff'),
        url('assets/fonts/OpenSans-SemiBold.woff2') format('woff2');
    font-weight: 500;
}

@font-face {
    font-family: OpenSans;
    src: url('assets/fonts/OpenSans-Bold.ttf') format('truetype'),
        url('assets/fonts/OpenSans-Bold.woff') format('woff'),
        url('assets/fonts/OpenSans-Bold.woff2') format('woff2');
    font-weight: bold;
}

@font-face {
    font-family: OpenSans;
    src: url('assets/fonts/OpenSans-ExtraBold.ttf') format('truetype'),
        url('assets/fonts/OpenSans-ExtraBold.woff') format('woff'),
        url('assets/fonts/OpenSans-ExtraBold.woff2') format('woff2');
    font-weight: 900;
}

@font-face {
    font-family: Poppins;
    src: url('assets/fonts/Poppins-Light.ttf') format('truetype'),
        url('assets/fonts/Poppins-Light.woff') format('woff'),
        url('assets/fonts/Poppins-Light.woff2') format('woff2');
    font-weight: 100;
}

@font-face {
    font-family: Poppins;
    src: url('assets/fonts/Poppins-Regular.ttf') format('truetype'),
        url('assets/fonts/Poppins-Regular.woff') format('woff'),
        url('assets/fonts/Poppins-Regular.woff2') format('woff2');
    font-weight: 400;
}

@font-face {
    font-family: Poppins;
    src: url('assets/fonts/Poppins-SemiBold.ttf') format('truetype'),
        url('assets/fonts/Poppins-SemiBold.woff') format('woff'),
        url('assets/fonts/Poppins-SemiBold.woff2') format('woff2');
    font-weight: 500;
}

@font-face {
    font-family: Poppins;
    src: url('assets/fonts/Poppins-Bold.ttf') format('truetype'),
        url('assets/fonts/Poppins-Bold.woff') format('woff'),
        url('assets/fonts/Poppins-Bold.woff2') format('woff2');
    font-weight: bold;
}

@font-face {
    font-family: Poppins;
    src: url('assets/fonts/Poppins-ExtraBold.ttf') format('truetype'),
        url('assets/fonts/Poppins-ExtraBold.woff') format('woff'),
        url('assets/fonts/Poppins-ExtraBold.woff2') format('woff2');
    font-weight: 900;
}

@font-face {
    font-family: Roboto;
    src: url('assets/fonts/Roboto-Light.ttf') format('truetype'),
        url('assets/fonts/Roboto-Light.woff') format('woff'),
        url('assets/fonts/Roboto-Light.woff2') format('woff2');
    font-weight: 100;
}

@font-face {
    font-family: Roboto;
    src: url('assets/fonts/Roboto-Regular.ttf') format('truetype'),
        url('assets/fonts/Roboto-Regular.woff') format('woff'),
        url('assets/fonts/Roboto-Regular.woff2') format('woff2');
    font-weight: 400;
}

@font-face {
    font-family: Roboto;
    src: url('assets/fonts/Roboto-Medium.ttf') format('truetype'),
        url('assets/fonts/Roboto-Medium.woff') format('woff'),
        url('assets/fonts/Roboto-Medium.woff2') format('woff2');
    font-weight: 500;
}

@font-face {
    font-family: Roboto;
    src: url('assets/fonts/Roboto-Bold.ttf') format('truetype'),
        url('assets/fonts/Roboto-Bold.woff') format('woff'),
        url('assets/fonts/Roboto-Bold.woff2') format('woff2');
    font-weight: bold;
}

@font-face {
    font-family: Roboto;
    src: url('assets/fonts/Roboto-Black.ttf') format('truetype'),
        url('assets/fonts/Roboto-Black.woff') format('woff'),
        url('assets/fonts/Roboto-Black.woff2') format('woff2');
    font-weight: 900;
}
